export const program = {
    Marți: [
        {
            name: "GTKEO",
            location: "Sală de Consiliu FIE",
            time: "10:00 - 12:30"
        },
        {
            name: "Conferinta STS",
            location: "Sală de Consiliu FIE",
            time: "12:30 - 13:00"
        },
        {
            name: "Conferință BCR",
            location: "Sală de Consiliu FIE",
            time: "13.00 - 13:45"
        },
        {
            name: "Conferință JTI",
            location: "Sală de Consiliu FIE",
            time: "14.00 - 14:45"
        },
        {
            name: "Challenge JTI",
            location: "TBD",
            time: "15.15 - 15:45"
        },
        {
            name: "Anunțare câștigători Challenge JTI",
            location: "TBD",
            time: "16:30 - 17:00"
        }
    ],
    Miercuri: [
        {
            name: "GTKEO",
            location: "TBD",
            time: "10:00 - 13:00"
        },
        {
            name: "Conferință Capgemini",
            location: "Sala de consiliu",
            time: "13.00 - 13:45"
        },
        {
            name: "Challenge Capgemini",
            location: "TBD",
            time: "14.30 - 15:00"
        },
        {
            name: "Anunțare câștigători Challenge Capgemini",
            location: "TBD",
            time: "15.30 - 16:00"
        },
        {
            name: "Extragere Tombolă",
            location: "TBD",
            time: "16:45 - 17:00"
        }
    ],
} 