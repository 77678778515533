import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

import Menu from "./Components/Menu";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Challenges from "./Pages/Challenges";
import FAQ from "./Pages/FAQ";
import Contact from "./Pages/Contact";
import Footer from "./Components/Footer";
import Devs from "./Components/Devs";

const AppLayout = () => (
  <>
    <Menu />
    <Outlet />
    <Footer />
  </>
);

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "challenges",
        element: <Challenges />,
      },
      {
        path: "FAQ",
        element: <FAQ />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "devs",
        element: <Devs />,
      },
    ],
  },
]);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//     <BrowserRouter>
//       <App />

//     </BrowserRouter>
//   </React.StrictMode>

// );

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
