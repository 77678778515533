import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuData } from "./MenuData";
import "./Menu.css";
import ButonAplica from "./ApplyButton";
import ButonAplica2 from "./ApplyButtonMenu";
import LogoNegru from "assets/eestec-fair-logo/logo_negru.svg";
import LogoAlb from "assets/eestec-fair-logo/logo_alb.svg";
import Hamburger from "assets/icons/hamburger.svg";

function Menu() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    setSidebar(!sidebar);
    if (!sidebar) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  };

  return (
    <div className="nav-wrapper">
      <div className="navbar">
        <Link to={"/"} className="nav-logo-link">
          <img
            className="nav-logo"
            src={LogoNegru}
            alt="EESTEC Fair logo"
          ></img>
        </Link>
        {MenuData.map((item, index) => {
          return (
            <li key={index} className="nav-text menu-text">
              <Link to={item.path}>
                {item.icon}
                <span className="menu-title">{item.title}</span>
              </Link>
            </li>
          );
        })}
        <li className="nav-button">
          <ButonAplica />
        </li>
      </div>
      <div className="menu-bars">
        <button className="toggle-button" onClick={showSidebar}>
          <img src={Hamburger} alt=""></img>
        </button>
      </div>

      {/* Sidebar */}
      <nav className={sidebar ? "sidebar active" : "sidebar"}>
        <Link to={"/"} className="sidebar-logo-link" onClick={showSidebar}>
          <img
            className="sidebar-logo"
            src={LogoAlb}
            alt="EESTEC Fair logo"
          ></img>
        </Link>
        <ul className="sidebar-items" onClick={showSidebar}>
          {MenuData.map((item, index) => {
            return (
              <li key={index} className="sidebar-text">
                <Link to={item.path}>
                  {item.icon}
                  <span className="menu-title">{item.title}</span>
                </Link>
              </li>
            );
          })}
          <li className="sidebar-button">
            <ButonAplica2 />
          </li>
        </ul>
      </nav>
      <div
        className={sidebar ? "overlay-active" : ""}
        onClick={showSidebar}
      ></div>
    </div>
  );
}

export default Menu;
