export const MenuData =  [
    {
        title: "Acasă",
        path: "/",
        cName: "nav-text" //className
    },
    {
        title: "Despre",
        path: "/about",
        cName: "nav-text" 
    },
    {
      title: "Challenges",
      path: "/challenges",
      cName: "nav-text" 
    },
    {
        title: "FAQ",
        path: "/faq",
        cName: "nav-text" 
    },
    {
        title: "Contact",
        path: "/contact",
        cName: "nav-text" 
    },
]