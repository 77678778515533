import { useState } from "react";

import "./HomeMobile.css";
import arrow_left from "assets/icons/arrow-left.svg";
import arrow_right from "assets/icons/arrow-right.svg";

import ApplyButton from "../Components/ApplyButton";
import Partners from "../Components/Partners";
import DateButton from "../Components/DateButton";
import LocationButton from "../Components/LocationButton";
import CountdownTimer from "Components/countdown";
import LogoNegru from "../assets/eestec-fair-logo/logo_negru.svg";
import GoToTop from "Components/GoToTop";
import ProgramDay from "Components/ProgramDay";

export default function HomeMobile() {
  const [whichDay, setWhichDay] = useState("Marți");
  const [wDay, setDay] = useState("Marți");

  const changeDay = () => {
    setWhichDay(whichDay === "Marți" ? "Miercuri" : "Marți");
    setDay(wDay === "Marți" ? "Miercuri" : "Marți");
  };

  return (
    <div className="home-wrapper">
      <a href="/">
        <img src={LogoNegru} className="mobileLogo2" alt=""></img>
      </a>
      <div className="partenersTextFlex acasa-text">
        <div className="partenersText">
          <p>Acasă</p>
        </div>
        <div className="titleUnderlineParteners"></div>
      </div>
      <div className="home-flex">
        <div className="aboutDiv">
          <p id="description-title">EESTEC Fair</p>
          <p id="description-content">
          EESTEC Fair este un eveniment de networking pentru studenți, 
            care își propune să creeze un mediu optim pentru ca aceștia 
            să interacționeze cu angajatorii din domeniu, de a-și dezvolta soft skill-urile, 
            dar și pentru a-și arăta abilitățile prin challenge-urile oferite de partenerii noștri.
          </p>
        </div>
        <div className="countdown-wrapper">
          <p className="countdown-mobile-text">Timp rămas până la eveniment:</p>
          <CountdownTimer />
        </div>
        <div className="button-wrapper">
          <DateButton />
          <LocationButton />
        </div>
        <ApplyButton />
      </div>

      <div class="program-header">
        <h2>Program</h2>
        <hr />
        <p>{wDay}</p>
        <hr/>
      </div>
      <div className="program-mobile">
        <div className="program-arrow">
          <img src={arrow_left} onClick={changeDay} alt="Left arrow"></img>
        </div>
        <ProgramDay name={whichDay} /> 
        <div className="program-arrow">
          <img src={arrow_right} onClick={changeDay} alt="Right arrow"></img>
        </div>
      </div>
      <Partners />
      <GoToTop />
    </div>
  );
}
