import React, { useState } from 'react';
import './contact-form.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  var [name, setName] = useState('');
  var [email, setEmail] = useState('');
  var [message, setMessage] = useState('');
  var used = 0;

  const handleSubmit = (e) => {
    e.preventDefault();
    if(document.getElementById("name-input").value === "" ||
       document.getElementById("email-input").value === "" ||
       document.getElementById("message-input").value === ""
      ){
        document.getElementById("Mesaj").innerHTML = "Câmpurile nu sunt completate.";
        document.getElementById("Mesaj").classList.add("fail");
        document.getElementById("fail-svg").style.display = "inline-block";
        if(used === 2){
          document.getElementById("Mesaj").classList.remove("succes");
          document.getElementById("success-svg").style.display = "none";
        }
        used = 1;
      }
    else{
        let emailTemp = 'jobfaireestec@gmail.com';
        let subject = "New message from " + name;
        emailjs.send(
            'service_dmkl2ei',
            'template_3cul3hn',
            {
                emailTemp,
                subject,
                message,
                name,
                email,
            },
            'cU0gcUBEMXTKbpKq-'
        )

        document.getElementById("name-input").value = "";
        document.getElementById("email-input").value = "";
        document.getElementById("message-input").value = "";
        document.getElementById("Mesaj").innerHTML = "Mesajul a fost trimis.";
        document.getElementById("Mesaj").classList.add("succes");
        document.getElementById("success-svg").style.display = "inline-block";
        document.getElementById("fail-svg").style.display = "none";
        used = 2;
      }
  };

  

  return (
    <div id="contact-form">
    <form onSubmit={handleSubmit}>
        <br/>
      <label htmlFor="name" id = "name">Nume și prenume:</label>
      <br/>
      <input
        type="text"
        id="name-input"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <br />
      <br/>
      <label htmlFor="email" id = "email">Adresă de email:</label>
      <br/>
      <input
        type="email"
        id="email-input"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <br/>
      <label htmlFor="message" id = "message">Mesajul tău:</label>
      <br/>
      <textarea
        id="message-input"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <br />
      <br />
      <button type="submit" id="trimite">Trimite</button>
      
      <svg width="15" id="fail-svg" height="15" display="none" viewBox="0 0 15 15" fill="#c55e2f" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 11.25C7.7125 11.25 7.89075 11.178 8.03475 11.034C8.17825 10.8905 8.25 10.7125 8.25 10.5C8.25 10.2875 8.17825 10.1093 8.03475 9.96525C7.89075 9.82175 7.7125 9.75 7.5 9.75C7.2875 9.75 7.1095 9.82175 6.966 9.96525C6.822 10.1093 6.75 10.2875 6.75 10.5C6.75 10.7125 6.822 10.8905 6.966 11.034C7.1095 11.178 7.2875 11.25 7.5 11.25ZM6.75 8.25H8.25V3.75H6.75V8.25ZM7.5 15C6.4625 15 5.4875 14.803 4.575 14.409C3.6625 14.0155 2.86875 13.4813 2.19375 12.8063C1.51875 12.1313 0.9845 11.3375 0.591 10.425C0.197 9.5125 0 8.5375 0 7.5C0 6.4625 0.197 5.4875 0.591 4.575C0.9845 3.6625 1.51875 2.86875 2.19375 2.19375C2.86875 1.51875 3.6625 0.98425 4.575 0.59025C5.4875 0.19675 6.4625 0 7.5 0C8.5375 0 9.5125 0.19675 10.425 0.59025C11.3375 0.98425 12.1313 1.51875 12.8063 2.19375C13.4813 2.86875 14.0155 3.6625 14.409 4.575C14.803 5.4875 15 6.4625 15 7.5C15 8.5375 14.803 9.5125 14.409 10.425C14.0155 11.3375 13.4813 12.1313 12.8063 12.8063C12.1313 13.4813 11.3375 14.0155 10.425 14.409C9.5125 14.803 8.5375 15 7.5 15Z" fill="#EF7B45"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" display="none" id="success-svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path d="M6.45 8.85L4.81875 7.21875C4.68125 7.08125 4.5125 7.0125 4.3125 7.0125C4.1125 7.0125 3.9375 7.0875 3.7875 7.2375C3.65 7.375 3.58125 7.55 3.58125 7.7625C3.58125 7.975 3.65 8.15 3.7875 8.2875L5.925 10.425C6.0625 10.5625 6.2375 10.6312 6.45 10.6312C6.6625 10.6312 6.8375 10.5625 6.975 10.425L11.2313 6.16875C11.3688 6.03125 11.4375 5.8625 11.4375 5.6625C11.4375 5.4625 11.3625 5.2875 11.2125 5.1375C11.075 5 10.9 4.93125 10.6875 4.93125C10.475 4.93125 10.3 5 10.1625 5.1375L6.45 8.85ZM7.5 15C6.4625 15 5.4875 14.803 4.575 14.409C3.6625 14.0155 2.86875 13.4813 2.19375 12.8063C1.51875 12.1313 0.9845 11.3375 0.591 10.425C0.197 9.5125 0 8.5375 0 7.5C0 6.4625 0.197 5.4875 0.591 4.575C0.9845 3.6625 1.51875 2.86875 2.19375 2.19375C2.86875 1.51875 3.6625 0.98425 4.575 0.59025C5.4875 0.19675 6.4625 0 7.5 0C8.5375 0 9.5125 0.19675 10.425 0.59025C11.3375 0.98425 12.1313 1.51875 12.8063 2.19375C13.4813 2.86875 14.0155 3.6625 14.409 4.575C14.803 5.4875 15 6.4625 15 7.5C15 8.5375 14.803 9.5125 14.409 10.425C14.0155 11.3375 13.4813 12.1313 12.8063 12.8063C12.1313 13.4813 11.3375 14.0155 10.425 14.409C9.5125 14.803 8.5375 15 7.5 15Z" fill="#1A936F"/>
      </svg>
      <p id="Mesaj"></p>

    </form>
    </div>
  );
};

export default ContactForm;