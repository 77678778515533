import "./Partners.css";
import Partner from "./Partner";

const Partners = () => (
  <div className="partners-div">
    <h2>Parteneri</h2>
    <hr />
    <div className="partners-wrapper">
      <div className="first-row">
        <h2>Anuali</h2>
        <hr />
        <Partner title="Anuali" name="anual" />
      </div>
      {/* <div className="second-row">
        <h2>Professional</h2>
        <hr />
        <Partner title="Professional" name="professional" />
      </div> */}
      <div className="third-row">
        <h2>Premium</h2>
        <hr />
        <Partner title="Premium" name="premium" />
        <div className="starter-div">
        <h2>Professional</h2>
        <hr />
        <Partner title="Professional" name="professional" />
        </div>
      </div>
      <div className="fourth-row">
        
        <div className="logistic-div">
        <h2>Business</h2>
        <hr />
        <Partner title="Business" name="business" />
        <h2>Starter</h2>
          <hr />
          <Partner title="Starter" name="starter" />
        </div>
        <div className="academic-div">
          
          <h2>Academici</h2>
          <hr />
          <Partner title="Academici" name="academic" />
          <h2>Logistic</h2>
        <hr />
        <Partner title="Logistic" name="logistic" />
        </div>
      </div>
    </div>
  </div>
);

export default Partners;
