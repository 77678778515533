import React, { useState, useEffect } from "react";
import countdown from "./countdown.css";
function CountdownTimer() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 1,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const endDate = new Date("2024-03-05 10:00:00");
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Calculate the time left until the end date
      const timeUntilEnd = Math.max(endDate - Date.now(), 0);
      const days = Math.floor(timeUntilEnd / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeUntilEnd % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeUntilEnd % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeUntilEnd % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endDate]);

  const { days, hours, minutes, seconds } = timeLeft;
  if (days === 0 && hours === 0 && minutes === 0 && seconds === 1) {
    return <div className="countdown-desktop-text"></div>;
  }

  return (
    <div className="marebloc">
      <div className="countdown-timer">
        <div className="container">
          <span className="countdown-timer__time">
            {days >= 10 ? days : "0" + days}
          </span>
          <span className="countdown-timer__time-label">d</span>
        </div>
        <span className="line"></span>
        <div className="container">
          <span className="countdown-timer__time">
            {hours >= 10 ? hours : "0" + hours}
          </span>
          <span className="countdown-timer__time-label">h</span>
        </div>
        <span className="line"></span>
        <div className="container">
          <span className="countdown-timer__time">
            {minutes >= 10 ? minutes : "0" + minutes}
          </span>
          <span className="countdown-timer__time-label">m</span>
        </div>
        <span className="line"></span>
        <div className="container">
          <span className="countdown-timer__time">
            {seconds >= 10 ? seconds : "0" + seconds}
          </span>
          <span className="countdown-timer__time-label">s</span>
        </div>
      </div>
    </div>
  );
}

export default CountdownTimer;
