import React from "react";
import "./Challenges.css";
import LogoNegru from "../assets/eestec-fair-logo/logo_negru.svg";
import location_icon from "assets/icons/purplelocation.svg";
import clock_icon from "assets/icons/clock.svg";

export default function challengesPage() {
  const startDate1 = new Date("2024-03-05 15:00:00");
  const startDate2 = new Date("2024-03-05 15:15:00");
  const startDate3 = new Date("2024-03-06 14:30:00");
  const endDate1 = new Date("2024-03-05 15:30:00");
  const endDate2 = new Date("2024-03-05 15:45:00");
  const endDate3 = new Date("2024-03-06 15:00:00");
  return (
    <div>
      <a href="/">
        <img src={LogoNegru} className="mobileLogo" alt=""></img>
      </a>
      <div className="page">
        <div className="title"> Challenges </div>
        {/* <div class="challengeCard">
          <img
            className="partnerImage"
            src="https://i.imgur.com/m2IJ9lz.png"
            alt=""
          />
          <div className="infoWrapper">
            <div className="challengeTitle"> Challenge Groupama </div>
            <div className="challengeTime"> JOI, 11:45 - 13:00 </div>
            <div className="challengeLocation"> Hol Rectorat </div>
            {Math.max(startDate1 - Date.now(), 0) <= 0 ? (
              Math.max(endDate1 - Date.now(), 0) <= 0 ? (
                <button class="clickyButton"> Finished </button>
              ) : (
                <a href="https://fair.eestec.ro/">
                  <button class="clickyButton"> Start Challenge </button>
                </a>
              )
            ) : (
              <button class="clickyButton"> Starting soon </button>
            )}
          </div>
        </div>
        <div class="challengeCard">
          <img
            className="partnerImage"
            src="https://i.imgur.com/4c68BGA.png"
            alt=""
          />
          <div className="infoWrapper">
            <div className="challengeTitle"> Challenge NXP </div>
            <div className="challengeTime"> JOI, 14:45 - 16:00 </div>
            <div className="challengeLocation"> Hol Rectorat </div>
            {Math.max(startDate2 - Date.now(), 0) <= 0 ? (
              Math.max(endDate2 - Date.now(), 0) <= 0 ? (
                <button class="clickyButton"> Finished </button>
              ) : (
                <a href="https://fair.eestec.ro/">
                  <button class="clickyButton"> Start Challenge </button>
                </a>
              )
            ) : (
              <button class="clickyButton"> Starting soon </button>
            )}
          </div>
        </div> */}
        {/* <div class="challengeCard">
        <img className="partnerImage" src="https://i.imgur.com/m2IJ9lz.png" alt=""/>
        <div className="infoWrapper">
          <div className="challengeTitle"> Anuntare castigatori Challenge Grupama  </div>
          <div className="challengeTime"> JOI, 16:00 - 17:00 </div>
          <div className="challengeLocation"> Hol Rectorat </div>
        </div>
      </div> */}
        {/* <div class="challengeCard">
        <img className="partnerImage" src="https://i.imgur.com/4c68BGA.png" alt=""/>
        <div className="infoWrapper">
          <div className="challengeTitle"> Anuntare castigatori Challenge NXP </div>
          <div className="challengeTime"> VINERI, 10:00 - 11:00 </div>
          <div className="challengeLocation"> Hol Rectorat </div>
        </div>
      </div> */}
        

        { <div class="challengeWrapper">
          <img
            className="partnerImage"
            src="https://i.imgur.com/i1Prtmk.png"
            alt=""
          />
          <div class="partnerDetails">
            <div className="challengeTitle"> 
              Challenge JTI 
            </div>
            <div className="challengeTime"> 
              <img src={clock_icon} alt="Date icon"></img>
              Marți, 15:15 - 15:45 
            </div>
            <div className="challengeLocation">
              <img src={location_icon} alt="Location icon"></img>
               TBD
            </div>
            {Math.max(startDate2 - Date.now(), 0) <= 0 ? (
              Math.max(endDate2 - Date.now(), 0) <= 0 ? (
                <button class="clickyButton"> Finished </button>
              ) : (
                <a href="https://fair.eestec.ro/">
                  <button class="clickyButton orange"> Start Challenge </button>
                </a>
              )
            ) : (
              <button class="clickyButton orange"> Starting soon </button>
            )}
          </div>
        </div> }
        { <div class="challengeWrapper">
          <img
            className="partnerImage"
            src="https://i.imgur.com/0GTtnMv.png"
            alt=""
          />
          <div class="partnerDetails">
            <div className="challengeTitle"> 
              Challenge Capgemini 
            </div>
            <div className="challengeTime"> 
              <img src={clock_icon} alt="Date icon"></img>
              Miercuri, 14:30 - 15:00 
            </div>
            <div className="challengeLocation">
              <img src={location_icon} alt="Location icon"></img>
              TBD
            </div>
            {Math.max(startDate3 - Date.now(), 0) <= 0 ? (
              Math.max(endDate3 - Date.now(), 0) <= 0 ? (
                <button class="clickyButton"> Finished </button>
              ) : (
                <a href="https://fair.eestec.ro/">
                  <button class="clickyButton orange"> Start Challenge </button>
                </a>
              )
            ) : (
              <button class="clickyButton orange"> Starting soon </button>
            )}
          </div>
        </div> }



        {/* <div class="challengeCard">
          <img
            className="partnerImage"
            src="https://i.imgur.com/veg99iu.png"
            alt=""
          />
          <div className="infoWrapper">
            <div className="challengeTitle"> Challenge Pepsico </div>
            <div className="challengeTime"> VINERI, 10:45 - 12:00 </div>
            <div className="challengeLocation"> Hol Rectorat </div>
            {Math.max(startDate3 - Date.now(), 0) <= 0 ? (
              Math.max(endDate3 - Date.now(), 0) <= 0 ? (
                <button class="clickyButton"> Finished </button>
              ) : (
                <a href="https://fair.eestec.ro/">
                  <button class="clickyButton"> Start Challenge </button>
                </a>
              )
            ) : (
              <button class="clickyButton"> Starting soon </button>
            )}
          </div>
        </div>
        <div class="challengeCard">
          <img
            className="partnerImage"
            src="https://i.imgur.com/fRpQPT3.png"
            alt=""
          />
          <div className="infoWrapper">
            <div className="challengeTitle"> Challenge P&G </div>
            <div className="challengeTime"> VINERI, 12:45 - 14:00 </div>
            <div className="challengeLocation"> Hol Rectorat </div>
            {Math.max(startDate4 - Date.now(), 0) <= 0 ? (
              Math.max(endDate4 - Date.now(), 0) <= 0 ? (
                <button class="clickyButton"> Finished </button>
              ) : (
                <a href="https://fair.eestec.ro/">
                  <button class="clickyButton"> Start Challenge </button>
                </a>
              )
            ) : (
              <button class="clickyButton"> Starting soon </button>
            )}
          </div>
        </div> */}
        {/* <div class="challengeCard">
        <img className="partnerImage" src="https://i.imgur.com/veg99iu.png" alt=""/>
        <div className="infoWrapper">
          <div className="challengeTitle"> Anuntare castigatori Challenge Pepsico  </div>
          <div className="challengeTime"> VINERI, 14:00 - 15:00 </div>
          <div className="challengeLocation"> Hol Rectorat </div>
        </div>
      </div> */}
        {/* <div class="challengeCard">
        <img className="partnerImage" src="https://i.imgur.com/fRpQPT3.png" alt=""/>
        <div className="infoWrapper">
          <div className="challengeTitle"> Anuntare castigatori Challenge P&G </div>
          <div className="challengeTime"> VINERI, 13:00 - 16:00 </div>
          <div className="challengeLocation"> Hol Rectorat </div>
        </div>
      </div> */}
      </div>
    </div>
  );
}
