import React, { useState } from "react";
import "./Question2.css";
import question_icon from "assets/icons/question.svg";

function Question({ text, hidden, hidden2,hidden3 }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <button
      className={`question-container2 ${expanded ? "expanded" : ""}`}
      onClick={() => setExpanded(!expanded)}
    >
      <div className="q-content2">
        <div className="question-text2">{text}</div>

        <div className="question-icon2">
          <img src={question_icon} className="q-icon" alt="Question icon"></img>
        </div>
      </div>
      <p className="hiddentext2">{hidden}</p>
      <p className="hiddentext2">{hidden2}</p>
      <p className="hiddentext2">{hidden3}</p>
    </button>
  );
}

export default Question;
