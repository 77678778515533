export const partners = {
  anual: [
    {
      name: "Uncle John",
      logo: "https://i.imgur.com/89RrSKR.png",
      url: "https://www.unclejohn.ro/",
    },
    {
      name: "BCR",
      logo: "https://i.imgur.com/vqAubnB.png",
      url: "https://www.bcr.ro/",
    },
    {
      name: "V7 Startup Studio",
      logo: "https://i.imgur.com/hI18lkL.png",
      url: "https://v7studio.ro/",
    },
    {
      name: "Mario's Pizza",
      logo: "https://i.imgur.com/80RaTU3.png",
      url: "https://mariospizza.ro/",
    },
    {
      name: "Rookeyz",
      logo: "https://i.imgur.com/odz9yjM.png",
      url: "https://www.rookeyz.com/",
    },
  ],
  professional: [
    {
      name: "BCR",
      logo: "https://i.imgur.com/vqAubnB.png",
      url: "https://www.bcr.ro/",
    },
    {
      name: "JTI",
      logo: "https://i.imgur.com/i1Prtmk.png",
      url: "https://www.jti.com/ro",
    },
  ],
  business: [
    {
      name: "P&G",
      logo: "https://i.imgur.com/HBeBcTq.png",
      url: "https://ro.pg.com/",
    },
    {
      name: "BAT",
      logo: "https://i.imgur.com/wZ8Zx6T.png",
      url: "https://www.bat.com/",
    },
    {
      name: "PTC",
      logo: "https://i.imgur.com/euzeby6.png",
      url: "https://www.ptc.com/",
    },
    {
      name: "Eagle Energy",
      logo: "https://i.imgur.com/dvPBPat.png",
      url: "https://eagleenergygroup.ro/",
    },
    {
      name: "STS",
      logo: "https://i.imgur.com/EQpewgj.png",
      url: "https://sts.ro/ro/",
    },
    {
      name: "hipo.ro",
      logo: "https://i.imgur.com/uPALAKM.png",
      url: "https://www.hipo.ro/",
    },
  ],
  premium: [
    {
      name: "Capgemini",
      logo: "https://i.imgur.com/0GTtnMv.png",
      url: "https://www.capgemini.com/",
    },
  ],
  logistic: [
    {
      name: "Telecom Academy",
      logo: "https://i.imgur.com/zRkTExb.png",
      url: "https://www.telacad.ro/",
    },
    {
      name: "PSXbox",
      logo: "https://i.imgur.com/JQ51e8j.png",
      url: "http://psxbox.ro/",
    },
    {
      name: "Bookzone",
      logo: "https://i.imgur.com/pCDXHYL.png",
      url: "https://bookzone.ro/",
    },
    {
      name: "LockedIn",
      logo: "https://i.imgur.com/9aEH6HD.png",
      url: "https://www.locked-in.ro/",
    },
  ],
  academic: [
    {
      name: "UPB",
      logo: "https://imgur.com/8oc1gSQ.png",
      url: "https://upb.ro/",
    },
  ],
  starter: [
    {
      name: "Avon",
      logo: "https://i.imgur.com/Fcim8oU.png",
      url: "https://www.avon.ro/",
    },
  ],
};
