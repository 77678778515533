import "./Footer.css"
import linkedin_icon from 'assets/icons/linkedin.svg'
import facebook_icon from 'assets/icons/facebook.svg'
import instagram_icon from 'assets/icons/instagram.svg'

function Footer() {
    return(
        <footer>
            <hr />
            <div className="footer-icons">
                <a href="https://www.linkedin.com/company/eestec-bucharest" target="_blank" rel="noreferrer" alt="LinkedIn EESTEC">
                    <img src={linkedin_icon} className="icon" alt="LinkedIn"></img>
                </a>
                <a href="https://www.facebook.com/EESTEC.LC.Bucharest" target="_blank" rel="noreferrer" alt="Facebook EESTEC">
                    <img src={facebook_icon} className="icon" alt="Facebook"></img>
                </a>
                <a href="https://www.instagram.com/eestec_lc_bucharest" target="_blank" rel="noreferrer" alt="Instagram EESETC">
                    <img src={instagram_icon} className="icon" alt="Instagram"></img>
                </a>
            </div>

            <p>Splaiul Independenței, nr. 313, AN204bis, UPB</p>
            <p> 0752 214 570 </p>
        </footer>
    )
}

export default Footer;