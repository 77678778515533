import { partners } from "assets/resources/partners";
import "./Partner.css";

function Partner(props) {
  return (
    <div className="partner">
        {partners[props.name].map((partner) => (
          <span key={partner.name}>
            <a
              className="partner-img"
              href={partner.url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={partner.logo}
                alt={`Logo for ${partner.name}`}
                className="partner-img"
              />
            </a>
          </span>
        ))}
      </div>
  );
}

export default Partner;
