import React, { useState } from "react";
import "./Question.css";
import question_icon from "assets/icons/question.svg";

function Question({text, hidden}) {
    const [expanded, setExpanded] = useState(false);
    return(
        
            <div className={`question-container ${expanded ? 'expanded' : ''}`}
                    onClick={() => setExpanded(!expanded)}>
                <div className="q-content"  >        
                    <div className="question-text">
                        {text}
                    </div>       
                    
                    <div className="question-icon">

                        <img src={question_icon} className="q-icon"  alt="Question icon"></img>
                    </div> 
                </div> 
                <p className="hidden-text">{hidden}</p>
 
                
            </div>
    )
}

export default Question;
