import "./cardbox.css";

export default function CardBox({ name, position, phone, mail, image }) {
  return (
    <div className="cardboxFlex">
      <img src={image} alt="" className="cardImage"></img>
      {/* <div className="pName">
        <p className="pNameText">{name}</p>
      </div>
      <div className="pTitle">
        <p className="pTitleText">{position}</p>
      </div>
      <div className="pPhone">
        <p className="pPhoneText">{phone}</p>
      </div>
      <div className="pMail"> 
        <p className="pMailText">{mail}</p>
      </div> */
      <div class="imageDetails">
        <div class="nameText">{name}</div>
        <div class="positionText">{position}</div>
        <div class="phoneText">{phone}</div>
        <div class="mailText">{mail}</div>
      </div>
      
      }
    </div>
  );
}
