import { program } from "assets/resources/program";
import './ProgramDay.css'

function ProgramDay(props) {
  return (
    <div className="programDayContainer">
      {program[props.name].map((day) => (
        <span key={day.name}>
          <span className="program-time">{day.time}</span>
          <div className="program-name"><span className="program-name-name">{day.name},</span> <span className="program-name-day">{day.location}</span></div>
        </span>
      ))}
    </div>
  );
}

export default ProgramDay;
