import "../Components/contactPage.css";
import CardBox from "Components/CardBox";
import ContactForm from "Components/contact-form";
import GoToTop from "../Components/GoToTop";
import LogoNegru from "../assets/eestec-fair-logo/logo_negru.svg";

export default function Contact() {
  return (
    <div id="container-whole-flex">
      <a href="/">
        <img src={LogoNegru} className="mobileLogo1" alt=""></img>
      </a>
      <div id="container-persons">
        <div className="contactPersonsTextFlex">
          <div className="contactPersonsText">
            <p>Persoane de contact</p>
          </div>
          <div className="titleUnderlineContact"></div>
        </div>

        <CardBox
          name="Bianca-Ioana Alexe"
          position="Președinte EESTEC București"
          phone="+40 752 214 570 "
          mail="bianca.alexe@eestec.ro"
          image="https://i.imgur.com/D53F7dl.png"
        />
        <CardBox
          className="contactPersonsCardContainer"
          name="Andreea Geamănu"
          position="Coordonator EESTEC Fair"
          phone="+40 762 425 145"
          mail="andreea.geamanu@eestec.ro"
          image="https://i.imgur.com/iaDgdWZ.png"
        />
      </div>

      <div id="container-contact">
        <div className="contactPersonsTextFlex">
          <div className="contactUs">
            <p>Contactează-ne</p>
          </div>
          <div className="titleUnderlineUs"></div>
        </div>

        <br />
        <br />
        <ContactForm />
        <br />
        <br />
      </div>
      <GoToTop />
    </div>
  );
}
