import "./HomeDesktop.css";

import ApplyButton from "../Components/ApplyButton";
import Partners from "../Components/Partners";
import DateButton from "../Components/DateButton";
import LocationButton from "../Components/LocationButton";
import CountdownTimer from "Components/countdown";
import GoToTop from "Components/GoToTop";
import Program from "Components/Program";

export default function HomeDesktop() {
  return (
    <div className="home-desktop-wrapper">
      <div className="home-left">
        <DateButton />
        <LocationButton />
        <ApplyButton />
      </div>
      <div className="home-right">
        <div className="about">
          <p id="description-title-desktop">EESTEC Fair</p>
          <p id="description-content-desktop">
            EESTEC Fair este un eveniment de networking pentru studenți, 
            care își propune să creeze un mediu optim pentru ca aceștia 
            să interacționeze cu angajatorii din domeniu, de a-și dezvolta soft skill-urile, 
            dar și pentru a-și arăta abilitățile prin challenge-urile oferite de partenerii noștri.
          </p>
        </div>
        <p className="countdown-desktop-text">Timp rămas până la eveniment:</p>
        <CountdownTimer />
        <Program />
        <Partners />
      </div>
      <GoToTop />
    </div>
  );
}
