import "./DateButton2.css";
import date_icon from "assets/icons/date.svg";

function DateButton() {
  return (
    <button className="date-button2">
      <span className="date-button-icon2">
        <img src={date_icon} alt="Date button icon"></img>
      </span>
      <span className="date-button-text2">5 - 6 Martie</span>
    </button>
  );
}

export default DateButton;
