import React from "react";
import Question from "../Components/Question";
import ButonAplica from "../Components/ApplyButton";
import "../Components/faq.css";
import faq from "../assets/images/faq.jpg";
import "../Components/Footer.css";
import "../Components/applyButton.css";
import GoToTop from "../Components/GoToTop";
import LogoNegru from "../assets/eestec-fair-logo/logo_negru.svg";

const FAQ = () => {
  const questions = [
    {
      text: "Cum mă înscriu?",
      hidden: "Pe site-ul nostru, îți introduci datele și CV-ul.",
      id: 1,
    },

    {
      text: "Pot participa dacă nu m-am înscris?",
      hidden:
        "Da, înscrierile se pot face și pe loc. Totuși, dacă te înscrii înainte de eveniment, intri în tombola pentru a câștiga unul din premiile noastre.",
      id: 2,
    },

    {
      text: "Pot participa la probe dacă nu sunt înscris?",
      hidden:
        "Da, dar pentru a-ți revendica premiul trebuie să te înscrii. După finalizarea activității, ai termen de 30 de minute pentru a te înscrie dacă nu ai făcut-o deja. Altfel, premiul va merge la cel de pe locul 1 care este înscris oficial la eveniment.",
      id: 3,
    },

    {
      text: "Trebuie să îmi formez o echipă pentru probe?",
      hidden: "Nu, probele sunt individuale.",
      id: 4,
    },

    {
      text: "Ce trebuie să știu pentru a rezolva probele?",
      hidden:
        "În principal informații de bază. Acestea vor fi construite astfel încât să poată fi rezolvate într-un timp limită de 20 de minute, existând totuși și ceva pentru a departaja participanții. Dificultatea va fi una medie.",
      id: 5,
    },

    {
      text: "La câte probe pot participa?",
      hidden: "La oricâte îți dorești, nu există un număr minim sau maxim.",
      id: 6,
    },

    {
      text: "Pot participa la prezentări dacă nu m-am înscris?",
      hidden: "Nu, dar vei avea posibilitatea să te înscrii și pe loc.",
      id: 7,
    },
  ];

  return (
    <div className="body">
      <a href="/">
        <img src={LogoNegru} className="mobileLogo3" alt=""></img>
      </a>
      <div className="FAQTextFlex">
        <div className="FAQText">
          <p>FAQ</p>
        </div>
        <div className="titleUnderlineFAQ"></div>
      </div>
      <img src={faq} className="bg" alt=""></img>
      
      <div className="FAQ">
        {questions.map(({ hidden, text, id }) => (
          <Question text={text} hidden={hidden} key={id} />
        ))}
      </div>

      <div className="apply">
        <ButonAplica className="aplica" />
      </div>
      <div className="bottomUnderlineFAQ"></div>
      <GoToTop />
    </div>
  );
};

export default FAQ;
