import ProgramDay from "./ProgramDay";
import "./Program.css";

function Program() {
  return (
    <div className="program-wrapper">
      <h2>Program</h2>
      <hr />
      <div className="program-content">
        <div className="joi">
          <h3>Marți, 5.03</h3>
          <hr />
          <ProgramDay name="Marți" />
        </div>
        <div className="vineri">
          <h3>Miercuri, 6.03</h3>
          <hr />
          <ProgramDay name="Miercuri" />
        </div>
      </div>
    </div>
  );
}

export default Program;
