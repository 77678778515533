import applyButon from "./applyButton.css";

export default function ButonAplica() {
  return (
    <a href="https://docs.google.com/forms/d/1HE92tDfNrDXbaTyf0eWRaGnXHdAM6rX163nb9YPoFxE/edit?usp=drive_web" className="noDecoration">
      <div className="aplica-box">
        <p className="aplica-text">Aplică</p>
      </div>
    </a>
  );
}
