import HomeMobile from "./HomeMobile";
import "../Components/Footer.css"; 
import HomeDesktop from "./HomeDesktop";
import { useState, useEffect } from "react";

export default function Home() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (width <= 958) return <HomeMobile />;
  else {
    return <HomeDesktop />;
  }
}
