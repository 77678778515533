import CardBox from "./CardBox";
import "./Devs.css";

export default function Devs() {
  return (
    <div className="devs-flex">
      <div className="family">
        <div className="titleUnderline-flex">
          <div className="text-arhitects">
            <p>Arhitects</p>
          </div>
          <div className="arhitectsUnderline"></div>
        </div>
        <div className="projectManager">
          <CardBox
            name="Gabi"
            position="Project Manager"
            image="https://i.imgur.com/VL1HkQE.png"
          ></CardBox>
        </div>
        <div className="teamFlex">
          <CardBox
            name="Denis"
            image="https://i.imgur.com/IAFVbvc.png"
          ></CardBox>
          <CardBox
            name="Alexia"
            image="https://i.imgur.com/9ix9ifT.png"
          ></CardBox>
          <CardBox
            name="Alex"
            image="https://i.imgur.com/BaWWpNZ.png"
          ></CardBox>
        </div>
      </div>

      <div className="family">
        <div className="titleUnderline-flex">
          <div className="text-templars">
            <p>Templars</p>
          </div>
          <div className="templarsUnderline"></div>
        </div>
        <div className="projectManager">
          <CardBox
            name="Rareș"
            position="Project Manager"
            image="https://i.imgur.com/ILEEr2v.png"
          ></CardBox>
        </div>
        <div className="teamFlex">
          <CardBox
            name="Silvia"
            image="https://i.imgur.com/LbEhzHb.png"
          ></CardBox>
          <CardBox
            name="Alex"
            image="https://i.imgur.com/BaWWpNZ.png"
          ></CardBox>
          <CardBox
            name="Mihai"
            image="https://i.imgur.com/zijGvjq.png"
          ></CardBox>
        </div>
        <CardBox
          name="Chat GPT"
          image="https://i.imgur.com/clrp0Jd.png"
        ></CardBox>
      </div>
    </div>
  );
}
