import "./applyButtonMenu.css";

export default function ButonAplica2() {
  return (
    <a href="https://docs.google.com/forms/d/1HE92tDfNrDXbaTyf0eWRaGnXHdAM6rX163nb9YPoFxE/edit?usp=drive_web" className="noDecoration">
      <div className="aplica-boxMenu">
        <p className="aplica-text">Aplică</p>
      </div>
    </a>
  );
}
