import "../Components/aboutPage.css";

import DateButton from "../Components/DateButton2";
import LocationButton from "../Components/LocationButton2";
import CardBox from "../Components/CardBox";
import Question2 from "../Components/Question2";
import ButonAplica from "Components/ApplyButton";
import GoToTop from "../Components/GoToTop";
import LogoNegru from "../assets/eestec-fair-logo/logo_negru.svg";

export default function About() {
  const questions = [
    {
      text: "Networking",
      hidden:
        "Networking-ul este un element de bază pentru a avea o viață profesională de succes, deoarece piața muncii se bazează pe cunoașterea și înțelegerea partenerilor și competitorilor. Prin intermediul networking-ului, aveți oportunitatea de a cunoaște și de a crea conexiuni cu persoane cheie din domeniul activității profesionale dorit.",
      hidden2: "",
      id: 1,
      hidden3:"",
    },

    {
      text: "Conferințe",
      hidden:
        "Prezentări pe diverse teme de interes vor fi ținute atât de reprezentanți ai companiilor partenere cât și de diferiți invitați independenți, în decursul celor două zile de eveniment pentru participanți.",
      hidden2: "",
      id: 2,
      hidden3:"",
    },

    {
      text: "Activități",
      hidden:
        "Challenge-uri rapide de la companiile partenere - rezolvarea unei întrebări tehnice contratimp. Partenerii noștri te vor remarca și, dacă rezolvi challenge-urile, vei beneficia și de niște premii.",
      hidden2:
        "Get To Know Each Other cu companiile partenere  - oportunitatea de a avea o discuție tip simulare de interviu, scopul final fiind de a te familiariza cu acest proces și de a primi un feedback constructiv.",
      id: 3,
      hidden3:"Stamp quest - strange 4 ștampile și intri în tombola pentru bilete Untold, Therme și multe altele",
    },
  ];

  return (
    <div id="content">
      <a href="/">
        {/* <img src={LogoNegru} className="mobileLogo" alt=""></img> */}
      </a>
      <div className="despreTextFlex">
        <div className="despreText">
          <p>Despre</p>
        </div>
        <div className="titleUnderline"></div>
      </div>
      <div class="despreDateLocation-flex">
        <p className="firstAboutParagraph">
          EESTEC Fair este un eveniment de networking aflat la a doua ediție, 
          care are ca scop dezvoltarea personală și profesională a studenților 
          în domeniul ingineriei și nu numai, prin oferirea unui cadru în care studenții 
          pot afla de oportunitățile de angajare și business oferite de partenerii noștri.
        </p>
        <div className="dateLocation-flex">
          <div className="flex-dataBox ">
            <DateButton />
          </div>
          <div className="flex-dataBox">
            <LocationButton />
          </div>
        </div>
      </div>

      <div>
        <div className="aboutSubtitle">
          <p>Sfaturi pentru participanți</p>
        </div>
        <div className="sfatUnderline"></div>
      </div>
      <div class="bulletquestions-flex">
        <span className="flex-bulletList">
          <div className="flex-bullet">
            <p className="bulletText">
              {" "}
              <span className="bulletspacing">
                <svg id="bullet"></svg>
              </span>
              Creează-ți un CV clar şi cât mai uşor de citit chiar la standul EESTEC.
            </p>
          </div>
          <div className="flex-bullet">
            <p className="bulletText">
              <span className="bulletspacing">
                <svg id="bullet"></svg>
              </span>
              Crează-ți și exersează-ți abilitățile necesare pentru o simulare de interviu.
            </p>
          </div>
          <div className="flex-bullet">
            <p className="bulletText">
              <span className="bulletspacing">
                <svg id="bullet"></svg>
              </span>
              Schițează-ți câteva întrebări pe care să le poți adresa fiecărui
              reprezentant.
            </p>
          </div>
        </span>
        <span className="questions-flex">
          {questions.map(({ hidden, text, id, hidden2, hidden3 }) => (
            <div className="margin">
              <Question2 text={text} hidden={hidden} key={id} hidden2={hidden2} hidden3={hidden3} />
            </div>
          ))}
        </span>
      </div>
      <br></br>
      <div >
        <div className="despreText">
          <p>Echipa</p>
        </div>
        <div className="titleUnderline"></div>
      </div>
      <div className="centerDivFlex">
        <div className="team-flex">

          <CardBox
            name="Andreea Geamănu"
            position="Coordonator EESTEC Fair"
            image="https://i.imgur.com/iaDgdWZ.png"
            // image="https://i.imgur.com/EREYhoU.png"
            />
          <CardBox
            name="Vișănescu Bogdan"
            position="Responsabil Corporate Relations"
            image="https://i.imgur.com/Vashucr.png"
          />
          <CardBox
            name="Andreea Mihalache"
            position="Responsabil Human Resources"
            image="https://i.imgur.com/N3ubhoS.png"
            />

          <CardBox
            name="Matei Cordoneanu"
            position="Responsabil Public Relations"
            image="https://i.imgur.com/tW6UehS.png"
          />
          <CardBox
            name="Bucur Andrei"
            position="Responsabil Tehnic "
            image="https://i.imgur.com/1b1PDLb.png"
          />
          <CardBox
            name="Răzvan Dinu"
            position="Responsabil Logistic "
            image="https://i.imgur.com/Cpb1Rzv.png"
          />
        </div>
      </div>
      <div className="lastUnderline-flex">
        <div className="lastUnderline"></div>
      </div>
      <GoToTop />
    </div>
  );
}
