import "./LocationButton.css";
import location_icon from "assets/icons/location.svg";

function LocationButton() {
  const handleClick = () => {
    const url =
      "https://www.google.com/maps/place/Facultatea+de+Inginerie+Electric%C4%83/@44.4365419,26.0458501,18z/data=!4m14!1m7!3m6!1s0x40b201ea132d5141:0xf5d5aecb94fa6dc7!2zVW5pdmVyc2l0YXRlYSBOYcibaW9uYWzEgyBkZSDImHRpaW7Im8SDIMiZaSBUZWhub2xvZ2llIFBPTElURUhOSUNBIEJ1Y3VyZciZdGk!8m2!3d44.4386064!4d26.0494925!16zL20vMDRsbDU2!3m5!1s0x40b201c6fa3be9b9:0x7fe519943c75e209!8m2!3d44.4361538!4d26.0458843!16s%2Fg%2F1yl450678?entry=ttu";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <button className="location-button" onClick={handleClick}>
      <span className="location-button-icon">
        <img src={location_icon} alt="Location button icon"></img>
      </span>
      <span className="location-button-text">Inginerie Electrică</span>
    </button>
  );
}

export default LocationButton;
