import './DateButton.css'
import date_icon from 'assets/icons/date.svg'

function DateButton() {
    return(
        <button className="date-button">
            <span className="date-button-icon">
                <img src={date_icon} alt="Date button icon"></img>
            </span>
            <span className="date-button-text">
                5 - 6 Martie
            </span>
        </button>
    )
}

export default DateButton;